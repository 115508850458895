import React from 'react';
import Image from '../../common/Image';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({

    Content: {
        [theme.breakpoints.up("md")]: {
          width: "60%",
          paddingTop: "1rem",
    
        }
      },    
    contenTitle: {
        padding: "1.4rem 0 0 1rem"
      },
      img: {
        "& img":{
          position:"static !important"
        },
        position: "relative",
        maxWidth: "40%",
        height: "100%",    
        margin: '30px auto',
        display: "block",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "80%",
        },
      },
}));

function HelpContentChildRight({data}) {
    const classes = useStyles();
    return (
        <div className={classes.Content}>
        <Typography >
          {data.map((item) => {
            return (
              <div>
                <Typography className={classes.contenTitle}>
                  {item.content}
                </Typography>
                <Image
                  className={classes.img}
                  {...item.img}
                />
              </div>

            )
          })}
        </Typography>
      </div>
    );
}

export default HelpContentChildRight;