import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ALink from '../../common/ALink'
import Typography from "@material-ui/core/Typography";
import HelpContentChildRight from "./HelpContentChildRight";
import Title from './Title'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      margin: " 0 1.5rem 0 1.5rem",
    },
  },
  Title: {
    padding: "1rem 1rem 2rem 0rem",
    borderBottom: "1px solid #005CB2",
    [theme.breakpoints.up("md")]: {
      width: "35%",
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  TitleChild: {
    marginBottom: "1rem",
    paddingLeft: "2rem",
    fontWeight: "300",
  },

  link: {
    textDecoration: "none",
    color: "black",
  },
  linkHove: {
    textDecoration: "none",
    color: "#005CB2",
  },

  help_title: {
    color: "#005CB2",
    marginBottom: "20px",
    padding: "1.5rem 0rem 0rem 1rem",
    fontSize: "24px",
  },
}))

export default function HelpContentChild(props) {

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.Title}>
        <Title className={classes.help_title} data={props.dataTitle} />
        {props.helpContent.map(item => {
          return (
            <div>
              <ALink
                className={
                  item.id === props.data.id ? classes.linkHove : classes.link
                }
                to={`/support/${item.id}`}
              >
                <Typography className={classes.TitleChild}>
                  {item.question}
                </Typography>
              </ALink>
            </div>
          )
        })}
      </div>
      <HelpContentChildRight data={props.data.answer} />
    </div>
  )
}
