import React from "react"
import { graphql } from "gatsby"
import withRoot from "../../components/shell/withRoot"
import HelpContentChild from "../../components/Help/sections/HelpContentChild"

function IndexPage({ data, params }) {
  const { id } = params
  return (
    <React.StrictMode>
      {params.id && (
        <HelpContentChild
          data={data.helpJson.help.help_content.find(i => i.id === id)}
          helpContent={data.helpJson.help.help_content}
          dataTitle={data.helpJson.help.help_title}
        />
      )}
    </React.StrictMode>
  )
}

export default withRoot(IndexPage)
export const query = graphql`
  {
    helpJson {
      help {
        help_title
        help_content {
          id
          question
          answer {
            content
            img {
              alt
              src {
                childImageSharp {
                  fluid {
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
      feed_back {
        feed_back_conten
        feed_back_title
      }
      learn_now {
        learn_now_content
        learn_now_title
        link {
          src
        }
      }
    }
  }
`
